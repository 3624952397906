import axios from 'axios'
import {ElMessage} from 'element-plus'
import {ElLoading} from 'element-plus'

axios.defaults.withCredentials = true; //让ajax携带cookie
// axios.defaults.baseURL = 'http://localhost:8081/lysz-clothing';
axios.defaults.baseURL = 'https://www.luyisizhe.com/lysz-clothing';
// axios.defaults.baseURL = 'https://10.167.213.41:8080';
// axios.defaults.headers.common['Authorization'] = this.$cookies.get("access_token");
// axios.defaults.headers.post['Authorization'] = localStorage.getItem("token");
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 8000;


// 添加请求拦截器s
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么N
    // if (config.method!='get'){
    //
    // }
    if (sessionStorage.getItem('token')) {
        config.headers['Authorization'] = sessionStorage.getItem("token");
    }
    config.withCredentials = false;
    axios.loadingInstance = ElLoading.service({fullscreen: true});
    return config;
}, function (error) {
    console.log("请求出错：" + error);
    // 对请求错误做些什么
    return Promise.reject('');
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    axios.loadingInstance.close();
    console.log("响应期：", response)
    if (response && response.status === 200) {
        if (response.data.code !== 0) {
            ElMessage({type: 'error', message: response.data.message, showClose: true});
            sessionStorage.removeItem("ly_user");
            this.$router.push("/login");
        } else {
            return response.data;
        }
    } else {
        ElMessage({type: 'error', message: '网络出问题了，请检查你的网络！', showClose: true})
    }
}, function (error) {
    ElMessage({type: 'error', message: '请求超时', showClose: true});
    return Promise.reject(error);
});
export default axios;


//api 路径
const BASE_URL = axios.defaults.baseURL;
const IMAGE_UPLOAD_URL = BASE_URL + '/clothing/upload';


export {
    IMAGE_UPLOAD_URL
}

